.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  background-color: var(--secondaryColor);
  margin-bottom: 10px;
 
}
.container-links{
  display: flex;
  flex-direction: row;
}

.logo img {
  height: 40px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 290px;
}

.links a {
  color: var(--color-paragraph); 
}

.links a:hover {
  color: var(--color-primary);
  transition: 0.7s
}

.contato{
  width: 230px;
  height: 48px; 
  padding: center; 
  border-radius: 8px; 
  border: 1px solid #C93E75; 
  background-color: #BF3475; 
  color: #ffffff; 
  font-family: 'Montserrat', sans-serif; 
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center; 
  justify-content: center; 
  text-decoration: none;  
}

.botao {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.button-menu-hamburguer{
  display: none;
}

.popup-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-menu.open {
  display: block;
}

.popup-menu-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.close-btn{
  margin-top: -5px;
  padding-left: 215px;
  height: 5px;
  width: 5px;
  background-color: white;
  color: var(--color-primary)
}

.links-responsive{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  gap:1rem;
}

@media (max-width: 732px) {

  .popup-menu-content {
    width: 80%;
    max-width: 300px;
  }


  .links {
    display: none;
  }

  .botao{
    display:none;
  }

  .button-menu-hamburguer{
    display: flex;
    margin-top: -2px;
    margin-right: 10px;
    width: 20px;

  }
  .hamburguer-icon{
   color: var(--color-primary);
  }

}