.call-us {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: var(--backgroundColor);
}
.call-us-left{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.call-us-left img{
  height: 50vw;
}

.form {
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding: 40px 40px 40px 40px;
  
}

.form label{
  margin-bottom: 5px;
}

.form input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
}

.form textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40vh;
}

button {
  background-color: var(--mainColor);
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: 1px;
  padding: 16px 28px;
  gap: 8px;
  font-size: 18px;
  font-weight: 700;
  color: var(--secondaryColor);
  margin-top: 32px;
}

@media (max-width: 732px) {
  .call-us {
    display: flex;
    flex-direction: column;
    padding-top: 50px;

  }

  .call-us-left{
    width: 100%;

  }
  
  .call-us-left img{
    height: 400px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;

    
  }

}