@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

:root {
  --mainColor: #bf3475;
  --secondaryColor: #fff;
  --hoverColor: #c93e75;
  --titleColor: #101828;
  --txtColor: #667085;
  --backgroundColor: #faf9fa;
  --mainFont: "Montserrat", sans-serif;
}

/* SEÇÃO PRINCIPAL */
.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  text-align: center;
  background-color: var(--backgroundColor);
  font-family: var(--mainFont);
  margin-top: 5vh;
}

.second-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
  padding-top: 6rem;
}

.section-web-d {
  display: flex;
  flex-direction: row;
  padding-top: 6rem;
  padding-left: 6rem;
  align-items: center;
  padding-bottom: 2rem;
}

/* HEADER */
.main-title {
  width: 100%;
  padding: 4rem 2.5rem;
  height: auto;
}

.secondary-title {
  color: var(--mainColor);
  font-weight: 400;
  font-size: 16px;
}

.subtitle {
  font-size: 36px;
  font-weight: 500;
  color: var(--titleColor);
  margin-bottom: 16px;
}

.text {
  color: var(--txtColor);
}

.section-one {
  display: flex;
  margin: 20px 0 0 80px;
}

.right-section {
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: end;
}

.section-txt {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  padding-right: 6rem;
  height: 538px;
}

.section-right {
  max-width: 100%;
  height: 538px;
  margin-bottom: 15px;
  align-items: flex-start;
  text-align: start;
  padding-top: 2.5rem;
  padding-left: 40px;
}

.section-txt-title {
  font-size: 30px;

  color: var(--titleColor);
  margin-bottom: 10px;
}

.section-txt-subtitle {
  font-size: 20px;
  color: var(--txtColor);
  margin-bottom: 20px;
}

.textP {
  display: flex;
  margin: 0 0 0 16px;
  font-family: var(--mainFont);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--txtColor);
  padding: 0.3rem;
}

.text-item-img {
  margin-right: 10px;
  width: 28px;
}

button {
  background-color: var(--mainColor);
  width: 240px;
  height: 54px;
  border-radius: 8px;
  border: 1px;
  padding: 16px 28px;
  gap: 8px;
  font-size: 18px;
  font-weight: 700;
  color: var(--secondaryColor);
  margin-top: 32px;
}

.section-img {
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: end;
  height: 538px;
}

.section-txt img,
.section-img img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.section-mockup {
  margin-top: -43px;
}

.left-img {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  padding-top: 5px;
}

.left-img img {
  max-width: 100%;
  height: 538px;
  margin-bottom: 15px;
}

.iconLight {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  position: initial;
  padding-bottom: 1.5rem;
}

.link-to-form {
  color: white;
}

@media (max-width: 732px) {
  .section {
    margin-top: 10vh;
  }

  .main-title {
    margin-bottom: -80px;
  }

  .main-title h1 {
    font-size: 30px;
  }

  .main-title p {
    font-size: 14px;
  }

  .section-one {
    display: flex;
    margin: 0px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-txt-title {
    font-size: 25px;
  }

  .section-txt-subtitle {
    font-size: 17px;
  }

  .text-item,
  .textP {
    font-size: 15px;
  }

  .section-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 0rem;
    padding: 0px 10px 0px 10px;
  }

  .section-web-d {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
    padding-left: 0rem;
    align-items: center;
    padding-bottom: 0rem;
    justify-content: center;
    align-items: center;
  }

  .section-img {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    padding-top: 10px;
  }

  .second-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-right: 0rem;
    padding-top: 0rem;
  }

  .section-right {
    max-width: 100%;
    height: auto;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 10px 0px 10px;
  }

  .left-img {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }

  .left-img img {
    height: 400px;
  }

  .text-item-img {
    align-items: start;
  }
}
