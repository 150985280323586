@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200&display=swap');

:root {
  --color-title: #101828;
  --color-paragraph: #667085;
  --color-primary: #BF3475;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

span {
  color: #ffffff;
}
body {
  font-family: 'Montserrat', sans-serif;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  max-width: 100vw;
}

button {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

a {
  text-decoration: none;
}

h1 {
  color: var(--color-title);
  font-size: 40px;
  font-weight: regular;

}

h2 {
  color: var(--color-title);
  font-size: 27px;

}

h3 {
  color: var(--color-title);
  font-size: 20px;
  font-weight: 400;

}

p {
  color: var(--color-paragraph);
  font-size: 15px;

}

.container {
  width: 100%
}